import axios from 'axios'

export const login = async (email, password) => {
  return axios
    .post(`${process.env.REACT_APP_API_ROOT}/auth/local`, {
      identifier: email,
      password: password,
    })
    .then((response) => {
      if (response.data.jwt) {
        localStorage.setItem('user', JSON.stringify(response.data));
      }

      return response.data;
    }).catch((error) => {

      return error.response.data;
    });
}

export const getModules = () => {

  let token = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user')).jwt;
  if (token) {
    return axios.get(`${process.env.REACT_APP_API_ROOT}/question-sections`,
      {
        headers: {
          "Accept": "application/json",
          "Authorization": "Bearer " + token
        }
      });
  }
  else {
    return null;
  }
}

export const forgot = async (email) => {
  return axios
    .post(`${process.env.REACT_APP_API_ROOT}/auth/forgot-password`, {
      email: email
    })
    .then((response) => {

      return response.data;
    }).catch((error) => {

      return error.response.data;
    });
}

export const resetpassword = async (code, password1, password2) => {
  return axios
    .post(`${process.env.REACT_APP_API_ROOT}/auth/reset-password`, {
      code: code,
      password: password1,
      passwordConfirmation: password2
    })
    .then((response) => {

      return response.data;
    }).catch((error) => {

      return error.response.data;
    });
}

export const getUsers = (companyId="") => {
  let token =
    localStorage.getItem("user") &&
    JSON.parse(localStorage.getItem("user")).jwt;
  return axios.get(`${process.env.REACT_APP_API_ROOT}/invites/myusers?companyId=${companyId}`, {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    }
  });
};

export const getVesselUsers = (id) => {
  let token =
    localStorage.getItem("user") &&
    JSON.parse(localStorage.getItem("user")).jwt;
  return axios.get(`${process.env.REACT_APP_API_ROOT}/vessels/${id}/crew`, {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    }
  });
};

export const getVesselHistory = (id) => {
  let token =
    localStorage.getItem("user") &&
    JSON.parse(localStorage.getItem("user")).jwt;
  return axios.get(`${process.env.REACT_APP_API_ROOT}/vessels/${id}/history`, {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    }
  });
};

export const getVessel = (id) => {
  let token =
    localStorage.getItem("user") &&
    JSON.parse(localStorage.getItem("user")).jwt;
  return axios.get(`${process.env.REACT_APP_API_ROOT}/vessels/${id}/getVesselInfo`, {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    }
  });
};

export const getVesselGallery = (id) => {
  let token =
    localStorage.getItem("user") &&
    JSON.parse(localStorage.getItem("user")).jwt;
  return axios.get(`${process.env.REACT_APP_API_ROOT}/vessels/${id}/gallery`, {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    }
  });
};