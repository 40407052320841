import React, { useEffect, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import PageHeader from "../../components/PageHeader/PageHeader";
import getBoardingTypes from "../../data/queries/getBoardingTypes";
import getJobRoles from "../../data/queries/getJobRoles";

export default function Profile({ userLoggedIn, displaySomeToast }) {
  const queryClient = useQueryClient();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [existingPassword, setExistingPassword] = useState("");
  const [boardingArrangement, setBoardingArrangement] = useState(0);
  const [role, setRole] = useState(0);
  const [rank, setRank] = useState(0);

  useEffect(() => {
    setFirstName(userLoggedIn.firstName);
    setLastName(userLoggedIn.lastName);
    setEmail(userLoggedIn.email);
    setBoardingArrangement(
      userLoggedIn.user_boarding_type && userLoggedIn.user_boarding_type.id
    );
    setRole(userLoggedIn.user_role && userLoggedIn.user_role.id);
    setRank(userLoggedIn.rank && userLoggedIn.rank.id);
  }, [userLoggedIn]);

  const savePassword = () => {
    if (password === password2) {
      passwordChangeMutation.mutate({ existingPassword, password2 });
    } else {
      displaySomeToast("Passwords do not match");
      return;
    }
  };

  const saveProfile = () => {
   
    if (!rank) {
      displaySomeToast("Please select rank");
    } else {
      editUserMutation.mutate({
        id: userLoggedIn?.id,
        rank: rank,
        vessel: userLoggedIn?.vessel?.id,
      });
    }
  };

  const ranks = useQuery(["ranks"], () => {
    let token =
      localStorage.getItem("user") &&
      JSON.parse(localStorage.getItem("user")).jwt;
    return axios.get(`${process.env.REACT_APP_API_ROOT}/ranks`, {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
  });

  const passwordChangeMutation = useMutation(
    ({ existingPassword, password2 }) => {
      let token =
        localStorage.getItem("user") &&
        JSON.parse(localStorage.getItem("user")).jwt;
      return axios.post(
        `${process.env.REACT_APP_API_ROOT}/auth/change-password`,
        {
          currentPassword: existingPassword,
          password: password2,
          passwordConfirmation: password2,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["user"]);
        displaySomeToast("Password Changed Successfully");
      },
      onError: (error) => {
        displaySomeToast(
          "Error changing password. Please ensure your existing password is correct, and your new password is different from your old password, and try again.",
          -1
        );
      },
    }
  );

  const editUserMutation = useMutation(
    () => {
      let token =
        localStorage.getItem("user") &&
        JSON.parse(localStorage.getItem("user")).jwt;
      return axios.post(
        `${process.env.REACT_APP_API_ROOT}/invites/edituser`,
        {
          userId: userLoggedIn?.id,
          rankId: rank,
          vesselId: userLoggedIn?.vessel?.id,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["user"]);
        displaySomeToast("Profile updated successfully");
      },
      onError: (error) => {
        displaySomeToast(
          "Error while update. Please try again",
          -1
        );
      },
    }
  );
  
  return (
    <>
      <div className="relative pb-5 border-b border-gray-200 sm:pb-0">
        <PageHeader title="My Profile" />
        <div className="mt-4">
          <div className="overflow-hidden shadow sm:rounded-md">
            <div className="bg-white px-4 py-5 sm:p-6">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-6 text-sm text-gray-700">
                  <div
                    className="border-t-4 border-fathom-blue rounded-b text-fathom-blue px-4 py-3 shadow-md"
                    role="alert"
                  >
                    <div className="flex">
                      <div className="py-1">
                        <svg
                          className="fill-current h-6 w-6 text-fathom-blue mr-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                        </svg>
                      </div>
                      <div>
                        <p className="font-bold">
                          Can't update your personal details?
                        </p>
                        <p className="text-sm">
                          To change any personal details please email
                          support@fathomsafety.com
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="firstName"
                    className="block text-sm font-medium text-gray-700 "
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    autoComplete="given-name"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-fathom-blue focus:ring-fathom-blue sm:text-sm disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    disabled={true}
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="lastName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Last Name
                  </label>
                  <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    autoComplete="family-name"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-fathom-blue focus:ring-fathom-blue sm:text-sm disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    disabled={true}
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    autoComplete="email"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-fathom-blue focus:ring-fathom-blue sm:text-sm disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    disabled={true}
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="rank"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Rank
                  </label>
                  <select
                    name="rank"
                    id="rank"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-fathom-blue focus:ring-fathom-blue sm:text-sm"
                    value={rank}
                    onChange={(e) => setRank(e.target.value)}
                  >
                    <option value="">Select Rank</option>
                    {ranks?.data?.data?.data?.map((rankItem) => (
                        <option key={rankItem.id} value={rankItem.id}>
                          {rankItem.attributes.name}
                        </option>
                    ))}
                  </select>
                </div>
              </div>
              </div>
              <div className="flex justify-between bg-gray-50 px-4 py-3 text-right sm:px-6">
                  <button
                    onClick={saveProfile}
                    className="inline-flex justify-center rounded-md border border-transparent bg-fathom-blue py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-fathom-dark-blue focus:outline-none focus:ring-2 focus:ring-fathom-blue focus:ring-offset-2"
                  >
                    Save
                  </button>
                </div>
                <div className="bg-white px-4 py-5 sm:p-6">
                <div className="grid grid-cols-6 gap-6">      
                <div className="col-span-6 sm:col-span-6">
                  <h1 className={"font-bold text-lg"}>Change Password</h1>
                </div>

                <div className="col-span-6 sm:col-span-2">
                  <label
                    htmlFor="password1"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Current Password
                  </label>
                  <input
                    type="password"
                    name="currentpassword"
                    id="currentpassword"
                    autoComplete="password"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-fathom-blue focus:ring-fathom-blue sm:text-sm"
                    onChange={(e) => setExistingPassword(e.target.value)}
                  />
                </div>
                <div className="col-span-6 sm:col-span-2">
                  <label
                    htmlFor="password1"
                    className="block text-sm font-medium text-gray-700"
                  >
                    New Password
                  </label>
                  <input
                    type="password"
                    name="password1"
                    id="password1"
                    autoComplete="new-password"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-fathom-blue focus:ring-fathom-blue sm:text-sm"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="col-span-6 sm:col-span-2">
                  <label
                    htmlFor="password2"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Repeat Password
                  </label>
                  <input
                    type="password"
                    name="password2"
                    id="password2"
                    autoComplete="new-password"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-fathom-blue focus:ring-fathom-blue sm:text-sm"
                    onChange={(e) => setPassword2(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-between bg-gray-50 px-4 py-3 text-right sm:px-6">
              <button
                onClick={savePassword}
                className="inline-flex justify-center rounded-md border border-transparent bg-fathom-blue py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-fathom-dark-blue focus:outline-none focus:ring-2 focus:ring-fathom-blue focus:ring-offset-2"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
