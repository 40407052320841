import axios from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";

/**
 * Completes module by calling custom back end function
 * @returns {any}
 */
export default function useUploadUsers(companyId) {
  const queryClient = useQueryClient();

  return useMutation(
    ({formData}) => {
      let token =
        localStorage.getItem("user") &&
        JSON.parse(localStorage.getItem("user")).jwt;
      return axios.post(
        `${process.env.REACT_APP_API_ROOT}/invites/upload?companyId=${companyId}`,
        formData,
        {
          headers: {
            Accept: "multipart/form-data",
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["users"]);
      },
    }
  );
}
