import { Fragment, useRef, useState, useContext } from "react";
import { Dialog, Transition  } from "@headlessui/react";
import ChartBarIcon from "@heroicons/react/24/outline/ChartBarIcon";
import CheckCircleIcon from "@heroicons/react/24/outline/CheckCircleIcon";
import axios from "axios";
import * as Sentry from "@sentry/react";
import { UserLevelContext } from "../../context/UserLevelContext";

export default function UserStatusModal({
  showModal,
  setShowModal,
  userProgress,
}) {
  const cancelButtonRef = useRef(null);
  const userLevel = useContext(UserLevelContext);
  
  //download cerficate PDF
  const getPdf = (id) => {
    let token =
      localStorage.getItem("user") &&
      JSON.parse(localStorage.getItem("user")).jwt;
    return axios
      .post(
        `${process.env.REACT_APP_API_ROOT}/user-modules/getcert`,
        { id: id },
        {
          headers: {
            Accept: "application/pdf",
            Authorization: "Bearer " + token,
          },
          responseType: "blob",
        }
      )
      .then((response) => {
        const file = new Blob([response.data], { type: "application/pdf" });

        const fileURL = URL.createObjectURL(file);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "certificate.pdf";
        alink.click();
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  };

  return (
    <div>
      <Transition.Root show={showModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={setShowModal}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-fathom-blue">
                    <ChartBarIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      User Progress for {userProgress.firstName}{" "}
                      {userProgress.lastName}
                    </Dialog.Title>
                    <div className="mt-4 text-sm">
                      <p>
                        <span className="text-gray-500">Status:</span> Accepted
                      </p>
                      <p>
                        <span className="text-gray-500">Role:</span>{" "}
                        {userProgress.user_role &&
                          userProgress.user_role.RoleName}
                      </p>
                      <p>
                        <span className="text-gray-500">Boarding Type:</span>{" "}
                        {userProgress.user_boarding_type &&
                          userProgress.user_boarding_type.BoardingTypeName}
                      </p>
                      {userProgress.progressPct == 100 && userLevel>=3 &&
                      <p>
                        <button
                          onClick={() => getPdf(userProgress.createdUserId)}
                          type="button"
                          className="bg-fathom-blue border border-transparent focus:outline-none font-medium hover:bg-fathom-dark-blue inline-flex items-center leading-4 mt-3 outline-none px-3 py-2 rounded-md shadow-sm text-sm text-white"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="h-4 mr-1"
                          >
                            <path
                            stroke="#fff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                            />
                          </svg>
                          Download Certificate
                        </button>
                      </p>
                      }
                      {/* <p>
                        <span className="text-gray-500">Certification:</span>{" "}
                        None or Valid until XX/XX/XXXX
                      </p> */}
                    </div>
                    <div className="mt-6">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50 text-center">
                          <tr>
                            <th
                              scope="col"
                              className="py-3.5 text-sm font-semibold text-gray-900"
                            >
                              Module
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-sm font-semibold text-gray-900"
                            >
                              Status
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {userProgress.progress &&
                            userProgress.progress.map((progress) => (
                              <tr key="">
                                <td className="whitespace-nowrap text-sm">
                                  {progress.module.ModuleName}
                                </td>
                                {progress.status == "COMPLETE" ? (
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">
                                    <div className="mx-auto flex items-center justify-center">
                                      <CheckCircleIcon
                                        className="h-6 w-6 text-green-600"
                                        aria-hidden="true"
                                      />
                                      <span className="ml-1 text-green-600"></span>
                                    </div>
                                  </td>
                                ) : (
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    Outstanding
                                  </td>
                                )}
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-fathom-blue text-base font-medium text-white hover:bg-fathom-dark-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-fathom-blue sm:text-sm"
                    onClick={() => setShowModal(false)}
                  >
                    Back to Users
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
