import React, { useEffect, useState, useMemo } from "react";
import DataTable from "react-data-table-component";

const VesselCrewTable = ({ data }) => {
  const filteredData = useMemo(
    () => data.filter((row) => row.rank?.name !== "management"),
    [data]
  );

  const columns = useMemo(
    () => [
      {
        label: "Rank",
        name: "Rank",
        selector: (row) => row.rank?.name,
        sortable: true,
        sortFunction: (a, b) => {
          const sortOrderA = a.rank?.sortOrder || 0;
          const sortOrderB = b.rank?.sortOrder || 0;
          return sortOrderA - sortOrderB;
        },
      },
      {
        label: "Name",
        name: "Name",
        selector: (row) => row.firstName + " " + row.lastName,
        sortable: true,
      },
      {
        label: "expiry",
        name: "Expiry",
        selector: (row) => row.expiry,
        sortable: true,
        cell: (row) => {
          const expiryDate = new Date(row.expiry);
          const formattedExpiry = isValidDate(expiryDate)
            ? expiryDate.toLocaleDateString("en-GB")
            : "NA";
          const color = isValidDate(expiryDate)
            ? getColorBasedOnExpiry(expiryDate)
            : "black"; // Assuming default color is black
          const cellStyle = {
            color: color,
          };
          return <div style={cellStyle}>{formattedExpiry}</div>;
        },
      },
    ],
    []
  );

  const getColorBasedOnExpiry = (expiryDate) => {
    const currentDate = new Date();
    const differenceInMilliseconds = expiryDate - currentDate;
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

    if (differenceInDays > 30) {
      return "green"; // More than a month to go
    } else if (differenceInDays > 0) {
      return "orange"; // Less than a month to go
    } else {
      return "#d80754"; // Expired, Fathom Red
    }
  };

  const isValidDate = (date) => {
    return date instanceof Date && !isNaN(date);
  };

  return (
    <div className="container mx-auto">
      <DataTable
        columns={columns}
        data={filteredData}
        pagination
        responsive
        striped
        hover
        condensed
      />
    </div>
  );
};

export default VesselCrewTable;
